const config = {
    apiUrl: "https://api.asia.windgis.lautec.com/",
    viewerUrl: "https://viewer.asia.windgis.lautec.com/",
    coreApiUrl: "https://core-api.lautec.com/v1/",
    dprApiUrl: "https://lautec-dpr-api-prod.azurewebsites.net/",
    coreAppUrl: "https://core.lautec.com/",
    landingUrl: "https://windgis.lautec.com",
    basemap: "mapbox://styles/lautec/ck949aof41kc41itbk257vk78",
    mapboxApiKey: "pk.eyJ1IjoibGF1dGVjIiwiYSI6ImNrNzRpNjFxaTBpc2IzZnBkNHU3MGJoMmoifQ.yNFawhQiPQGVujuEwb-TIg",
    moduleId: "979.PkNiNyg-",
    cryptoUtilsPass: "5hTMdM2p",
    azureB2C: {
        auth: {
            clientId: "2293eaaf-1943-41b8-95b6-6994c87a9dab", // This is the ONLY mandatory field that you need to supply.
            authority: "https://identity.lautec.com/lautecb2cprod.onmicrosoft.com/b2c_1a_signin", // Defaults to "https://login.microsoftonline.com/common"
            passwordResetAuthority: "https://identity.lautec.com/lautecb2cprod.onmicrosoft.com/b2c_1a_passwordreset",
            knownAuthorities: ["identity.lautec.com"], // Mark your B2C tenant's domain as trusted.
            redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
            postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        },
        system: {
            allowRedirectInIframe: true
        }
    },
    loginRequest: {
        scopes: ["https://lautecb2cprod.onmicrosoft.com/apis/default"]
    },
    forgetPasswordErrorCode: "AADB2C90118",
    coreModule: {
        id: "Core",
        name: "Core",
        description: "Account Management",
        status: 1,
        locationUrl: "https://core.lautec.com/",
        favIconUrl: "https://lautecprod.blob.core.windows.net/externals/favicon-core-module.svg",
        landingPageUrl: "https://core.lautec.com/"
    },
    analyticsModuleId: "iUko.wY.DRI-"
};

export default config;
